<template>
    <div class="ce-content">

        <div class="row ce-row">

            <!-- First col ------------------------>
            <div class="col-md-12">

                <!-- ce-content-inner ------------------------------>
                <div class="ce-content-inner">
                    <div class="row roles-page-wrap client-roles-wrap">
                        <div class="col-md-12 roles-page-header">
                            <h3 class="ce-create-client-list-card title">Clients Group</h3>
                            <div class="ce-create-role-btn-wrap">
                                <button class="btn ce-btn-create-role-submit" data-toggle="modal"
                                        data-target="#ce-create-groups">
                                    Create new
                                </button>
                            </div>
                        </div>
                        
                        <div class="col-md-12">
                            <!-- Create role Modal -->
                            <div class="modal fade ce-create-role" id="ce-create-groups" tabindex="-1" role="dialog"
                                 aria-labelledby="ce-create-role-modal" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 v-if="isEdit" class="modal-title" id="ce-role-edit-modal">Edit client groups</h5>
                                            <h5 v-else class="modal-title" id="ce-role-create-modal">Create new client groups</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="reset()">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="ce-content-inner client-roles-wrap">
                                                <div class="cr-name">
                                                    <label for="cr-name-input">Name</label>
                                                    <input type="text" v-model="newGroup.name" id="cr-name-input" placeholder="Enter Group Name">
                                                </div>

                                                <div class="select-cg">
                                                    <p>Select Roles:</p>
                                                  <div class="form-group">
                                                    <!--<div class="scr-select-all">
                                                        <input type="checkbox" id="scr-select-all" onselect="selectAll()">
                                                        <label for="">All</label>
                                                    </div>-->
                                                    <div v-for="role in clientRoles" :key="role.id" class="scr-checkbox">
                                                      <input type="checkbox" :value="role.id" v-model="newGroup.client_roles">
                                                      <label>{{role.name}} ({{role.sso_clients.name}})</label>
                                                    </div>
                                                  </div>

                                                </div>
                                                
                                                <div class="cg-remark">
                                                    <label for="cg-remark-input">Remarks</label>
                                                    <input type="text" v-model="newGroup.description" id="cg-remark-input" placeholder="Enter Description">
                                                </div>
                                                        
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button v-on:click="reset" type="button" class="btn btn-danger"
                                                    data-dismiss="modal">Cancel
                                            </button>
                                            <button v-if="isEdit" v-on:click="createGroup" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Update
                                            </button>
                                            <button v-else v-on:click="createGroup" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Create role Modal End -->
                            <!-- Assign User Modal -->
                            <div class="modal fade ce-assign-role" id="ce-assign-group" tabindex="-1" role="dialog"
                                 aria-labelledby="ce-assign-role-modal" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="ce-group-assign-modal">Assign Group to Users</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    Group
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="form-group">
                                                        {{bulkAssignData.group}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row agtu-multiselect-row">
                                                <div class="col-sm-3">
                                                    Users
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="form-group">
                                                        <Multiselect
                                                            v-model="selectedUsers"
                                                            :options="users"
                                                            :showLabels="false"
                                                            label="name"
                                                            :searchable="true"
                                                            placeholder="Select Users"
                                                            :multiple="true"
                                                            :close-on-select="false"
                                                            :clear-on-select="false"
                                                            track-by="id"
                                                            >
                                                            <template slot="selection" slot-scope="{ values, isOpen }">
                                                                <span
                                                                class="multiselect__single"
                                                                v-if="values.length &amp;&amp; !isOpen"
                                                                >{{ values.length }} options selected</span
                                                                >
                                                            </template>
                                                        </Multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                          <!--<div class="row agtu-checkbox">
                                            <div class="col-sm-12">
                                              <div class="form-group">
                                                <input
                                                    id="agtu-checkbox-id"
                                                    type="checkbox"
                                                    v-model="bulkAssignData.is_sync"
                                                    true-value="1"
                                                    false-value="0"
                                                    placeholder=""
                                                >
                                                <label for="agtu-checkbox-id">Remove all other permissions and client access.</label>

                                              </div>
                                            </div>
                                          </div>-->
                                        </div>
                                        <div class="modal-footer">
                                            <button v-on:click="reset" type="button" class="btn btn-danger"
                                                    data-dismiss="modal">Cancel
                                            </button>
                                            <button v-on:click="saveBulkAssign" type="button" data-dismiss="modal"
                                                    class="btn btn-primary">Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Assign Group To users Modal End -->
                          <!-- Create Modal For Showing Scopes Start-->

                          <div class="modal fade ce-assign-role" id="show-users" tabindex="-1" role="dialog"
                               aria-labelledby="ce-assign-role-modal" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="ce-role-assign-modal">View All Scopes</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="ce-content-inner">
                                    <li class="" v-for="sso_user in this.ssoUsersForGroup" :key="sso_user.id">{{sso_user.name}} ( {{sso_user.email}} ) </li>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button v-on:click="reset" type="button" class="btn btn-danger"
                                          data-dismiss="modal">Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Create Modal For Showing Scopes End -->
                        </div>
                    </div>

                    <div class="row new-list-table user-list-table" id="main-data-container">
                        <div class="col-12">
                            <div class="list-table-header">
                                <div class="list-header id">ID</div>
                                <div class="list-header name">Name</div>
                                <div class="list-header description">Description</div>
                              <div class="list-header description">Roles</div>
                              <div class="list-header description">Assigned To</div>
                                <div class="list-header created">Created At</div>
                                <div class="list-header actions">Actions</div>
                            </div>

                            <div class="list-table-body">
                                <div class="list-row" v-for="(group,index) in groups" :key="group.role_id">
                                    <div class="list-body id">{{index+1}}</div>
                                    <div class="list-body name">{{group.name}}</div>
                                    <div class="list-body description">{{group.description}}</div>
                                  <div class="list-body email"> <div> <li v-for="client_role in group.client_roles" :key="client_role.id">{{client_role.name}} ({{client_role.sso_clients ? client_role.sso_clients.name : 'SSO'}})</li> </div></div>
                                  <div class="list-body description"> <div v-if="group.sso_users.length <= 1"><li v-for="sso_user in group.sso_users" :key="sso_user.id">{{sso_user.name}}</li></div><div v-else><a href data-toggle="modal" data-target="#show-users" @click="loadUsers(group)">View All</a></div></div>
                                    <div class="list-body created">{{group.created_at}}</div>
                                    <div class="list-body actions">
                                        <div class="edit">
                                            <span name="Edit Group" @click="editGroup(group)" title="EDIT GROUP" data-toggle="modal" data-target="#ce-create-groups">
                                                <i class="cts-configure cts_recipient_icon"></i>
                                            </span>
                                        </div>
                                        <div class="users">
                                            <span name="Assign to Users" @click="bulkAssign(group)" title="ASSIGN GROUP TO USERS" data-toggle="modal" data-target="#ce-assign-group">
                                                <i class="cts-group cts_recipient_icon"></i>
                                            </span>
                                        </div>
                                        <div class="delete">
                                            <span name="Delete Group" @click="deleteGroup(group)" title="DELETE GROUP">
                                                <i class="cts-delete cts_recipient_icon"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            
                </div>
                <!-- ce-content-inner End ------------------------>

            </div>
            <!-- First col End -------------------------------->

        </div>
    </div>
</template>

<style>
.client-roles-wrap .roles-page-header .ce-create-role-btn-wrap {
    left: 190px;
}
#ce-create-groups .modal-content .modal-body .cr-name {
    margin-bottom: 25px;
}
#ce-create-groups .modal-content .modal-body .cr-name label, #ce-create-groups .modal-content .modal-body .cg-remark label {
    width: 25%;
}
#ce-create-groups .modal-content .modal-body .cr-name input, #ce-create-groups .modal-content .modal-body .cg-remark input {
    width: 75%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    background: #f8f9fa;
    font-weight: 600;
    border: 1px solid #00000033;
}
#ce-create-groups .modal-content .modal-body .select-cg {
    margin-bottom: 25px;
}
#ce-create-groups .modal-content .modal-body .select-cg .scr-checkbox{
    max-height: 200px;
    overflow-y: auto;
}
#ce-create-groups .modal-content .modal-body .select-cg p {
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 14px;
}
#ce-create-groups .modal-content .modal-body .select-cg input {
    display: inline-block;
    margin-top: 12px;
    cursor: pointer;
    margin-left: 10px;
}
#ce-create-groups .modal-content .modal-body .select-cg label {
    padding-left: 35px;
    margin-top: -28px;
    padding-top: 13px;
    padding-bottom: 14px;
    display: block;
    background: #e4e5ea4d;
    font-size: 14px;
    margin-bottom: 3px;
}
#ce-create-groups .modal-content .modal-body .cg-remark {
    margin-bottom: 20px;
}
.ce-main-wrapper .ce-wrapper .ce-content .ce-content-inner .ce-create-role-btn-wrap .ce-btn-create-role-submit {
    width: 180px;
    height: 45px;
    background: #00ccff;
    color: #fff;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
}
.cts_recipient_icon{
    color:#2cc4ef;
    font-size:18px;
    cursor: pointer;
    margin: 7px;
}
.multiselect__tags .multiselect__single, .send_form .input_box input, .multiselect__tags .multiselect__placeholder {
    font-size: 14px !important;
    color: #656568 !important;
}
.multiselect__tags {
    border: 1px solid #bfbfbf !important;
    padding-left: 15px;
}
.multiselect__tags .multiselect__single {
    overflow: hidden;
}
.multiselect .multiselect__tags {
    height: 40px;
}
.cts-group.cts_recipient_icon {
    font-weight: bold;
}
.agtu-checkbox {
  opacity: 0.8;
}
.agtu-checkbox label {
  cursor: pointer;
  margin-left: 6px;
  margin-top: 10px;
}
#ce-assign-group .modal-content .modal-body .col-sm-9 .form-group {
  font-weight: bold;
  opacity: 0.9;
}
.multiselect__content-wrapper {
  opacity: 1!important;
}
.agtu-multiselect-row .col-sm-9 .form-group {
  opacity: 1!important;
}
.multiselect__option--highlight {
  background: #00ccff!important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import Api from '../includes/Api';
import moment from 'moment';
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      isEdit: 0,
      editIndex:null,
      newGroup: {
        name : null,
        description: null,
        client_roles:[]
      },
      bulkAssignData:{
        group: null,
        users: [],
        is_sync:0
      },
      clientRoles: [],
      groups: [],
      users:[],
      selectedUsers:[],
      ssoUsersForGroup:[]
    }
  },
  methods: {
    createGroup() {

      this.$confirm(
          {
            message: 'Are you sure you want to create this Group?',
            button: {
              no: "No",
              yes: "Yes"
            },
            callback: confirm => {
              if (confirm){

                let root = this;
                if ( root.newGroup.name === '' || root.newGroup.name === undefined || root.newGroup.name === null ){
                  Api.alert("warning", "Name cannot be empty.");
                } else if ( root.newGroup.description === '' || root.newGroup.description === undefined || root.newGroup.description === null ) {
                  Api.alert("warning", "Description cannot be empty.");
                } else if ( root.newGroup.client_roles.length <= 0 ) {
                  Api.alert("warning", "Please select Roles.");
                } else {
                  Api.post('/save-group', root.newGroup).then((response) => {

                    if (response.data.status === 'success') {
                      if( this.newGroup.id === null && (response.data.data !== null || response.data.data !== undefined) ){
                        this.getAllGroups();
                      } else if ( this.newGroup.id !== null && (response.data.data !== null || response.data.data !== undefined) ) {
                        this.$set(this.groups,this.editIndex,response.data.data);
                      }
                      this.reset();
                      Api.alert('success', response.data.message);
                    }
                  });
                }

              }
            }
          }
      )
    },
    getAllGroups() {

      let root = this;
      root.groups=null;
      Api.get('/list-group').then((response) => {
        response.data.data.forEach(item => {
          item.created_at = moment(item.created_at).format('lll');
        });
        root.groups = response.data.data;
      });
    },
    deleteGroup(group) {
      this.$confirm(
          {
            message: 'Are you sure you want to delete this group?',
            button: {
              no: "No",
              yes: "Yes"
            },
            callback: confirm => {
              if (confirm){
                Api.get('/delete-group/'+group.id).then((resp) => {
                  if (resp.data.status === 'success') {
                    Api.alert('success', resp.data.message);
                  }
                  let index = this.groups.indexOf(group);
                  if (index !== -1) {
                    this.groups.splice(index, 1);
                  }
                });
              }
            }
          }
      )
    },
    editGroup(group) {
      this.isEdit=1;
      this.editIndex = this.groups.indexOf(group);
      Api.get('/list-group-by-id/'+group.id).then((resp) => {
        if (resp.data.status === 'success') {
          this.newGroup.id = resp.data.data.id;
          this.newGroup.name = resp.data.data.name;
          this.newGroup.description = resp.data.data.description;
          this.getAllClientRoles();
          resp.data.data.client_roles.forEach(item => {
            this.newGroup.client_roles.push(item.id);
          });
        }
      });
    },
    getAllClientRoles(){
      Api.get('/list-client-role').then((resp) => {
        if (resp.data.status === 'success') {
          this.clientRoles = resp.data.data;
        }
      });
    },
    reset(){
      this.isEdit = 0;
      this.editIndex = null;
      this.newGroup.id= null;
      this.newGroup.name=null;
      this.newGroup.description=null;
      this.newGroup.client_roles = [];
      this.bulkAssignData.group=null;
      this.selectedUsers = [];
      this.bulkAssignData.users=[];
      this.bulkAssignData.is_sync=0;
    },
    getAllUsers(){
      Api.get('/list-user').then((resp) => {
        if (resp.data.status === 'success') {
          this.users = resp.data.users;
        }
      });
    },
    bulkAssign(group){
      this.bulkAssignData.id=group.id;
      this.bulkAssignData.group=group.name;
    },
    saveBulkAssign(){
      this.selectedUsers.forEach(item=>
          this.bulkAssignData.users.push(item.id)
      );
      this.$confirm(
          {
            message: 'Are you sure you want to save all Users?',
            button: {
              no: "No",
              yes: "Yes"
            },
            callback: confirm => {
              if (confirm){

                let root = this;
                if ( root.bulkAssignData.group === '' || root.bulkAssignData.group === undefined || root.bulkAssignData.group === null ){
                  Api.alert("warning", "Name cannot be empty.");
                } else if ( root.bulkAssignData.users.length <= 0 ) {
                  Api.alert("warning", "Please select Users.");
                } else {
                  Api.post('/bulk-add-users-to-group', root.bulkAssignData).then((response) => {
                    if (response.data.status === 'success') {
                      this.getAllGroups();
                      this.reset();
                      Api.alert('success', response.data.message);
                    }
                  });
                }

              }
            }
          }
      )
    },
    loadUsers(group){
      this.ssoUsersForGroup = group.sso_users;
    }
  },
  created() {
    this.getAllGroups();
    this.getAllClientRoles();
    this.getAllUsers();
  }
}
</script>

